@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Font Import */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --primary: #0056D2;
  --primary-dark: #0045A8;
  --secondary: #00C2FF;
  --accent: #FF4C00;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  color: #334155;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Better Animation Defaults */
.fade-in {
  opacity: 0;
  animation: fadeIn 0.8s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animation Delays */
.delay-100 {
  animation-delay: 0.1s;
}
.delay-200 {
  animation-delay: 0.2s;
}
.delay-300 {
  animation-delay: 0.3s;
}
.delay-400 {
  animation-delay: 0.4s;
}
.delay-500 {
  animation-delay: 0.5s;
}

/* Button Styling */
.btn {
  @apply font-medium px-6 py-3 rounded-lg transition-all duration-200 inline-flex items-center justify-center;
}

.btn-primary {
  @apply bg-primary text-white hover:bg-primary-dark shadow-md hover:shadow-lg;
}

.btn-secondary {
  @apply bg-white text-primary border border-primary hover:bg-gray-50 shadow-sm;
}

.btn-outline {
  @apply border border-gray-300 text-gray-700 hover:border-primary hover:text-primary;
}

/* Card Styling */
.card {
  @apply bg-white rounded-xl shadow-md overflow-hidden transition-all duration-300;
}

.card:hover {
  @apply shadow-lg transform -translate-y-1;
}

/* Input Styling */
input, textarea, select {
  @apply outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 transition-all duration-200;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0;
}

/* Improve Focus Styles for Accessibility */
*:focus-visible {
  outline: 2px solid var(--primary);
  outline-offset: 2px;
}

/* Custom Container Max Widths */
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1400px; /* Custom max-width for extra-large screens */
  }
}

/* Gradient Text */
.text-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, var(--primary), var(--secondary));
}

/* Back to Top Button */
.back-to-top {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 99;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
}

.back-to-top.visible {
  opacity: 1;
  visibility: visible;
}

.back-to-top:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
}

.hover-grow {
  transition: transform 0.3s ease;
}

.hover-grow:hover {
  transform: scale(1.05);
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.pulse {
  animation: pulse 2s infinite;
}

.icon-circle {
  background-color: rgba(59, 130, 246, 0.1);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.icon-circle i {
  font-size: 24px;
  color: #3b82f6;
}
